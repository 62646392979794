import ImgLoader from "./ImgLoader";
import CanvasRender from "./CanvasRender";

const requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

class ScrollSequence {
    constructor(opts) {
        this.opts = {
            container: 'body',
            imagesRoot: '',
            cover: false,
            scroll: null,
            ...opts
        }

        this.container = typeof opts.container === 'object' ?
            opts.container :
            document.querySelector(opts.container);

        this.countImages = opts.images.length
        this.images = Array(this.countImages);
        this.imagesToLoad = opts.images;
        this.priorityFrames = opts.priorityFrames;

        this.loader = new ImgLoader({
            imgsRef: this.images,
            images: this.imagesToLoad,
            imagesRoot: this.opts.imagesRoot,
            priorityFrames: this.priorityFrames,
            ready: opts.ready
        });

        this.canvas = new CanvasRender({
            container: this.container,
            images: this.images,
            cover: this.opts.cover
        });

        this.init();
    }

    init() {
        this.canvas.setup();
        this.loader.once('FIRST_IMAGE_LOADED', () => {
            this.canvas.renderIndex(0);
            // console.log('FIRST_IMAGE_LOADED');
        })
        this.loader.once('PRIORITY_IMAGES_LOADED', () => {
            let imgs = this.countImages;
            let progress = 0, mapToIndex = 0;
            let key = this.opts.scrollKey;
            
            if (this.opts.scroll != null && key != null) {
                this.opts.scroll.on('scroll', (args) => {
                    if (typeof args.currentElements[key] === 'object') {
                        progress = args.currentElements[key].progress;
                        mapToIndex = Math.floor(progress * imgs);
                        requestAnimationFrame(() => this.canvas.renderIndex(mapToIndex));
                    }
                });
            }
        })
        this.loader.once('IMAGES_LOADED', () => {
            // console.log('Sequence Loaded');
        })
        this.loader.once('MINIMUM_LOADED', () => {
            this.opts.ready();
        })
        this.loader.once('IMAGE_LOADED', (args) => {
            let pct = this.loader.loadIndex / this.loader.minimumIndex;
            if (pct >= 0 && pct < 1) {
                // document.querySelector('.productLoading').innerHTML = Math.round(pct * 100) + '%';

            }
        })
    }
}

export default ScrollSequence;