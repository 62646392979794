import anime, { stagger } from 'animejs';
import LocomotiveScroll from 'locomotive-scroll';
import ScrollSequence from './ScrollSequence';

const updateVh = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const isMobile = () => {
    return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
};

updateVh();

if (!isMobile()) {
    window.addEventListener('resize', updateVh);
}


let disableTransitions =  window.innerWidth < 1200;
let scroll;
let introTimeout;

const scrollTo = (elt, duration = 1000, callback = null, easing = [0.86,0,0.07,1]) => {
    scroll.scrollTo(elt, { duration: duration, easing: easing, callback: callback });
};

const goToIntro = () => {
    scrollTo('.design1', 1500, () => { scroll.start() }, [0,0,1,1]);
};

/* ------------------------------------ */
/* ------ Locomotive scroll init ------ */
/* ------------------------------------ */
const createScroll = (container) => {
    let elt = container.querySelector('[data-scroll-container]');
    let horizontalValue = elt.getAttribute('data-horizontal');
    let direction = "horizontal";// horizontalValue == 'true' ? 'horizontal' : 'vertical';
    
    // if (disableTransitions){
    //     direction = 'vertical';
    // }

    scroll = new LocomotiveScroll({
        el: elt,
        smooth: true,
        getSpeed: true,
        getDirection: true,
        direction: direction,
        gestureDirection: 'both'
    });

    scroll.stop();

    introTimeout = setTimeout(() => {
        goToIntro();
    }, 10000);

    return scroll;
};


let onWheelIntro = (event) => {
    if (event.deltaY > 5 || event.deltaY < -5) {
        clearTimeout(introTimeout)
        goToIntro();
        document.removeEventListener("wheel", onWheelIntro);
    }
};

const initPage = () => {

    document.addEventListener("wheel", onWheelIntro);

    const imagesPath = '/assets/images/sequences/';
    const designImages = [];
    let fileName;
    for (let i = 0; i <= 45; i++) {
        fileName =  'car-anim' + i.toString().padStart(4, '0') + '.png'
        designImages.push(fileName);
    }

    new ScrollSequence({
        container: '.background_render--design',
        images: designImages,
        imagesRoot: imagesPath + 'design/',
        priorityFrames: [],
        cover: true,
        scroll: scroll,
        scrollKey: 'animationDesign',
        ready: () => {
            console.log("Design Ready");
        }
    });

    const teachImages = [];
    for (let i = 46; i <= 72; i++) {
        fileName =  'car-anim' + i.toString().padStart(4, '0') + '.png'
        teachImages.push(fileName);
    }

    new ScrollSequence({
        container: '.background_render--tech',
        images: teachImages,
        imagesRoot: imagesPath + 'tech/',
        priorityFrames: [],
        cover: true,
        scroll: scroll,
        scrollKey: 'animationTech',
        ready: () => {
            console.log("Tech Ready");
        }
    });


    // MENU

    let navToggle = document.querySelector('.navbar_toggle');
    
    let menu = document.querySelector('.menu');
    let menuCover = document.querySelector('.menuCover');
    let mainNav = document.querySelector('.menu main nav');
    let menuRender = document.querySelector('.menu_render');
    let menuRenderView = document.querySelector('.menu_render_view');
    let menuRenderItems = document.querySelectorAll('.menu_render_item');
    let initialActiveIndex = 0;

    const openMenuItem = (index) => {
        document.querySelector('.menu_render_item.opened').classList.remove('opened');
        menuRenderItems[index].classList.add('opened');
        anime({
            targets: menuRenderView,
            translateY: index * -1 * window.innerHeight * 0.17,
            duration: 800,
            easing: 'easeOutExpo',
        })
    };

    // INIT MENU POSITION
    let activeLi = mainNav.querySelector('.active');
    let lis = Array.prototype.slice.call( mainNav.querySelector('ul').children );
    if (activeLi) {
        initialActiveIndex = lis.indexOf(activeLi);
    }
    if (initialActiveIndex != 0) {
        menuCover.classList.add('small');
    }

    openMenuItem(initialActiveIndex);

    navToggle.addEventListener('click', () => {
        if (navToggle.classList.contains('opened')) {
            // CLOSE ---------------------------------------
            anime.remove(menuCover);
            anime({
                targets: menuCover,
                opacity: [0, 1],
                duration: 200,
                easing: 'linear',
                complete: () => {
                    // let bounds = document.querySelector('.menu_render_item.opened').getBoundingClientRect();
                    // let end = 'polygon(' + bounds.left + 'px ' + bounds.top + 'px, ' + (bounds.left + bounds.width) + 'px ' + bounds.top + 'px, ' + (bounds.left + bounds.width) + 'px ' + (bounds.top + bounds.height) + 'px, ' + bounds.left + 'px ' + (bounds.top + bounds.height) + 'px)';

                    menuCover.style.clipPath = null;
                    setTimeout(() => {
                        navToggle.classList.remove('opened');
                        menu.classList.remove('opened');
                        anime({
                            targets: menuCover,
                            opacity: [1, 0],
                            duration: 200,
                            easing: 'linear'
                        });
                    }, 800);
                }
            });
        } else {
            // OPEN ----------------------------------------
            anime({
                targets: menuCover,
                opacity: [0, 1],
                duration: 200,
                easing: 'linear',
                complete: () => {
                    navToggle.classList.add('opened');
                    menu.classList.add('opened');

                    let bounds = document.querySelector('.menu_render_item.opened').getBoundingClientRect();
                    let end = 'polygon(' + bounds.left + 'px ' + bounds.top + 'px, ' + (bounds.left + bounds.width) + 'px ' + bounds.top + 'px, ' + (bounds.left + bounds.width) + 'px ' + (bounds.top + bounds.height) + 'px, ' + bounds.left + 'px ' + (bounds.top + bounds.height) + 'px)';

                    menuCover.style.clipPath = end;

                    anime({
                        targets: menuCover,
                        opacity: [1, 0],
                        duration: 100,
                        delay: 600,
                        easing: 'linear',
                    });
                }
            });
        }
    });

    
    mainNav.querySelectorAll('a').forEach((link, index) => {
        link.addEventListener('mouseenter', () => {
            openMenuItem(index);
        });
    });

    mainNav.addEventListener('mouseleave', () => {
        let activeLi = mainNav.querySelector('.active');
        let lis = Array.prototype.slice.call( mainNav.querySelector('ul').children );
        if (activeLi) {
            let activeIndex = lis.indexOf(activeLi);
            openMenuItem(activeIndex);
        }
    });

};

const initScrollEvents = () => {
    scroll.on('call', (value, way, obj) => {
        let calls = (typeof value === 'string') ? [value] : value;
        
        calls.forEach(call => {
            if (call === 'activateBackground') {
                let val = obj.el.getAttribute('data-value');
                console.log("Apply state", way, val);

                if (way === 'enter') {
                    if (document.querySelector('.background_render.active')) {
                        document.querySelector('.background_render.active').classList.remove('active');
                    }
                    if (val != 'none' && document.querySelector(val)) {
                        document.querySelector(val).classList.add('active');
                    }
                }
                else if (way === 'exit') {
                    if (val != 'none' && val != '.background_render--team' && document.querySelector(val)) {
                        document.querySelector(val).classList.remove('active');
                    }
                }
            }
            else if (call === 'activateTech' && way === 'enter') {
                console.log('ACTIVATETECH');
                scroll.stop();
                setTimeout(() => {
                    scrollTo('.tech1', 900, () => { scroll.start() }, [0,0,1,1])
                }, 100);
            }
            else if (call === 'showH2') {
                console.log('Show H2');
                if (way == "enter") {
                    document.querySelector('.background_render--tech').classList.remove('active');
                    document.querySelector('.h2Render').classList.add('visible');
                    console.log('H2 ENTER');
                    
                } else {
                    document.querySelector('.h2Render').classList.remove('visible');
                    console.log('H2 OUT');
                }
            }
            else if (call === 'updateState' && way === 'enter') {
                let state = obj.el.getAttribute('data-value');
                let section = obj.el.closest('.pSection');

                section.setAttribute('data-state', state);
            }
        });
    });
}

(function() {

    scroll = createScroll(document);
    initScrollEvents();
    initPage();

})();