class EventEmitter {
    listeners = {}
    addListener(eventName, fn) {
        this.listeners[eventName] = this.listeners[eventName] || [];
        this.listeners[eventName].push(fn);
        return this;
    }
    on(eventName, fn) {
        return this.addListener(eventName, fn);
    }
    once(eventName, fn) {
        this.listeners[eventName] = this.listeners[eventName] || [];
        const onceWrapper = () => {
            fn();
            this.off(eventName, onceWrapper);
        }
        this.listeners[eventName].push(onceWrapper);
        return this;
    }
    off(eventName, fn) {
        return this.removeListener(eventName, fn);
    }
    removeListener(eventName, fn) {
        let lis = this.listeners[eventName];
        if (!lis) return this;
        for (let i = lis.length; i > 0; i--) {
            if (lis[i] === fn) {
                lis.splice(i, 1);
                break;
            }
        }
        return this;
    }
    emit(eventName, ...args) {
        let fns = this.listeners[eventName];
        if (!fns) return false;
        fns.forEach((f) => {
            f(...args);
        });
        return true;
    }
    listenerCount(eventName) {
        let fns = this.listeners[eventName] || [];
        return fns.length;
    }
    rawListeners(eventName) {
        return this.listeners[eventName];
    }
}

class ImgLoader extends EventEmitter {
    constructor(opts) {
        super();
        this.images = opts.imgsRef;
        this.imageNames = opts.images;
        this.imagesRoot = opts.imagesRoot;
        this.sequenceLength = opts.images.length;
        this.priorityFranes = opts.priorityFrames;
        this.complete = false;
        this.loadIndex = 0;
        this.minimumIndex = Math.round(this.imageNames.length * 0.4);
        
        this.priorityQueue = this.createPriorityQueue();
        this.loadingQueue = this.createLoadingQueue();

        this.loadNextImage();
    }

    loadImage(e) {
        if (this.images[e]) {
            return this.loadNextImage();
        }
        const onLoad = () => {
            img.removeEventListener('load', onLoad);
            this.images[e] = img;

            if (e == this.minimumIndex) {
                this.emit('MINIMUM_LOADED');
            }
            
            if (e === 0) {
                this.emit('FIRST_IMAGE_LOADED');
            }

            this.loadIndex++;
            this.emit('IMAGE_LOADED');

            this.loadNextImage();
        }
        const img = new Image;
        img.addEventListener('load', onLoad);
        img.src = (this.imagesRoot ? this.imagesRoot : '') + this.imageNames[e];
    }

    loadNextImage() {
        if (this.priorityQueue.length) {
            this.loadImage(this.priorityQueue.shift());
            if (!this.priorityQueue.length) {
                this.emit('PRIORITY_IMAGES_LOADED');
            }
        } else if (this.loadingQueue.length) {
            this.loadImage(this.loadingQueue.shift())
        } else {
            this.complete = true;
            this.emit('IMAGES_LOADED');
        }
    }

    createPriorityQueue() {
        const p = this.priorityFrames || [];
        if (!p.length) {
            p.push(0);
            p.push(Math.round(this.sequenceLength / 2));
            p.push(this.sequenceLength - 1);
        }
        return p;
    }

    createLoadingQueue() {
        return this.imageNames.map((s, i) => i).sort((e, n) => {
            return n;//Math.abs(e - this.sequenceLength / 2) - Math.abs(n - this.sequenceLength / 2)
        });
    }
}

export default ImgLoader;